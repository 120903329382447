.installed-mods {
  .mod-title {
    font-size: 1em;
    font-weight: bold;
    margin: 0 0 .25em;

    .mod-name {
      font-size: 1em;
    }

    .mod-version {
      font-size: .8em;
    }
  }

  .mod-info {
    font-size: .8em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    text-align: center;
    gap: .75em;
    justify-content: space-around;

    li {
      box-sizing: border-box;
      padding: .5em;
      border: .125em solid;
      border-radius: .3em;
    }
  }
}
