.labelled-input {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .4em;
  padding-bottom: .3em;

  input[type="checkbox"], input[type="radio"] {
    flex: 0 0 auto;
  }

  label {
    flex: 1 1 100%;

    p {
      margin: 0;
      font-size: .85em;
      opacity: .85;
    }
  }
}
