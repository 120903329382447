@use "../variables";
@use "../dialog";

.settings-dialog {
  flex-flow: row nowrap;
  align-items: stretch;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  box-sizing: border-box;
  gap: 1.5em;
  position: relative;

  &:not([hidden]) {
    display: flex;
  }

  &.full-size {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    margin: auto;
    box-shadow: none;
  }

  .close-button-wrap {
    flex: 0 0 auto;
    width: 0;

    .close-button {
      position: absolute;
      border: 0;
      padding: .25em;
      margin: 0;
      background-color: transparent;
      transform: translate(-.75em, -.75em);
      width: 1em;
      height: 1em;
      z-index: 2;
      font-size: 1.5em;
      box-sizing: content-box;
    }
  }
}

.settings-navigation {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  flex: 0 0 20rem;

  .nav-button {
    margin: 0 0 .5em;
    text-align: left;
    width: auto;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }

    &.nav-current {
      background-color: variables.$dialog-input-border;
    }
  }
}

.settings-ui-wrap {
  display: block;
  overflow: auto;
  flex: 1 1 auto;
  padding-right: 1em;
  box-sizing: border-box;
}

.settings-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(variables.$dialog-bg, .85);
  backdrop-filter: blur(8px);

  .muted {
    opacity: .5;
  }
}
